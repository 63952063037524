.featured-posters{
  grid-column: span 12;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 1.563rem;
  padding: 1.563rem;
  align-items: stretch;
  @include mq(md){
    margin:  0 -1.563rem;
  }
  img{
    object-fit: cover;
    min-height: 100%;
  }
  &.single{
    display: block;
    a{
      display: block;
      max-height: 100%;
    }
    picture{
      display: block;
      height: 100%;
      width: 100%;
    }
    img{
      max-height: calc(100vh - 1.563rem * 2);
      width: 100%;
      object-fit: cover !important;
    }
    .featured-article--text{
      width: 50%;
      margin: 0 auto;
    }
    .featured-article--text h3{
      margin-bottom: 0.2em;
      margin-top: 1.75em;
    }
    .featured-article--text .abstract {
      font-size: var(--fs-text-s);
      line-height: var(--lh-text-s);
      margin-top: 0.75em;
    }
    .read-more {
      @extend .underline;
    }
    .read-more{
      margin-top: 0.7rem;
    }
  }
}

#home header{
  position: fixed;
  width: 100%;
  background: white;
  top: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity $transition-duration-default $transition-timing-default;
  &.show{
    opacity: 1;
    pointer-events: auto;
    transition: opacity $transition-duration-default $transition-timing-default 0.2s;
  }
}

#home main{
  padding-top: 0;
  height: 100%;
}

#home .big-logo{
  position: fixed;
  left: 50%;
  @include mq(md){
    min-width: 305px;
    top: calc(1.563rem * 2);
  }
  min-width: 180px;
  transform: translateX(-50%);
  top: 2rem;
  opacity: 0;
  pointer-events: none;
  transition: opacity $transition-duration-default $transition-timing-default;
  &.show{
    opacity: 1;
    pointer-events: auto;
    transition: opacity $transition-duration-default $transition-timing-default 0.2s;
  }
}

#home .featured-articles{
  .featured-article{
    max-width: 500px;
    @include mq(md){
      max-width: none;
    }
    margin: 0 auto;
  }
  .featured-article--cover{
    margin-top: 1rem;
  }
  .landscape, .square {
    img{
      width: 100%;
    }
    @include mq(md){
      width: 50%;
    }
    margin: 0 auto;
  }
  .portrait {
    img{
      width: 100%;
    }
    @include mq(md) {
      width: 35%;
    }
    margin: 0 auto;
  }
  .read-more{
    margin-top: 0.5rem;
    @extend .underline;
  }
}

#home .category-header{
  margin-top: 3.75rem;
}

#home .about-section{
  height: 100vh;
  grid-column: span 12;
  margin: 3.75rem -1.563rem 0;
  display: flex;
  flex-direction: column;
  @include mq(md){
    flex-direction: row;
    margin: 3.75rem -1.563rem 0;
  }
  @include mq(md){
    display: flex;
    gap: 1.563rem;
  }
  .about-text{
    padding: 1.563rem calc(1.563rem *2) 2rem;
    display: grid;
    flex: 1;
    grid-template-rows: 1fr min-content;
    @include mq(md){
      width: 50%;
      padding: 1.563rem calc(1.563rem *2) 2rem;
    }
    h3{
      @extend .underline;
      margin-top: 0.7rem;
    }
  }
  .about-text--cover{
    margin-top: 1.563rem;
    img{
      width: 30%;
    }
  }
  .about-image{
    width: 100%;
    overflow: hidden;
    flex: 1;
    display: flex;
    @include mq(md){
      width: 50%;
      max-height: 100%;
    }
    picture{
      display: block;
      height: 100%;
      width: 100%;
      @include mq(md){
        display: block;
        height: 100%;
        width: 100%;
      }
      display: block;
    }
    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}