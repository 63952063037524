.articles-grid{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  flex-wrap: wrap;
  grid-column: span 12;
  justify-content: center;
  padding: 3.75rem 1.563rem 0 1.563rem;
  grid-gap: 1.563rem;
  @include mq(md){
    padding: 3.75rem 6% 0 6%;
  }


  h1.see-more{
    text-align: center;
    grid-column: span 2;
  }
}
.articles-grid--item {
}
.articles-grid--item {
  flex: 0 0 calc(8/12 * 100%); /* Initially set to 8/12 of the container width *//* Add some margin between items */
}

/* If customOrientation() returns "portrait", set the size to 4/12 of the container width */
.articles-grid--item.portrait {
  flex-basis: calc(4/12 * 100%);
}

/* If there are 2 or more landscape images in a row, center them */
.articles-grid--item.landscape {
  flex-basis: calc(8/12 * 100%); /* Set flex to 1 to allow it to grow and occupy remaining space */
}

/* Center the content of the item */
.articles-grid--item > div, .articles-grid--item > p {
  text-align: center;
}

.articles-grid--item{
  display: grid;
  grid-template-rows: 1fr min-content;
}

.article-grid--item--cover{
  img{
    object-fit: cover;
    height: 100%;
  }
}
.articles-grid--item--title{
  margin-top: 0.5em;
  @include font(title);
  @include fs(text-s);
  text-align: center;
}