// CONFIGURATE DOCUMENT

$fontsize: (
  text-xs: (
    xs: (
      fs: 8px,
      lh: 1.2rem,
    ),
    sm: (
      fs: 8px,
      lh: 1.2rem,
    ),
    md: (
      fs: 8px,
      lh: 1.2rem,
    ),
  ),
  menu-s: (
    xs: (
      fs: 9.33px,
      lh: 1.2rem,
    ),
    sm: (
      fs: 9.33px,
      lh: 1.2rem,
    ),
    md: (
      fs: 9.33px,
      lh: 1.2rem,
    ),
  ),
  text-s: (
    xs: (
      fs: 14px,
      lh: 1.2rem,
    ),
    sm: (
      fs: 14px,
      lh: 1.2rem,
    ),
    md: (
      fs: 14px,
      lh: 1.2rem,
    ),
  ),
  main: (
    xs: (
      fs: 20px,
      lh: 20px,
    ),
    sm: (
      fs: 24px,
      lh: 24px,
    ),
    md: (
      fs: 24px,
      lh: 24px,
    ),
  ),
  text-m: (
    xs: (
      fs: 20px,
      lh: 20px,
    ),
    sm: (
      fs: 24px,
      lh: 24px,
    ),
    md: (
      fs: 30px,
      lh: 32px,
    ),
  ),
  text-l: (
    xs: (
      fs: 20px,
      lh: 20px,
    ),
    sm: (
      fs: 24px,
      lh: 24px,
    ),
    md: (
      fs: 46px,
      lh: 1.1em,
    ),
  ),
);

$margin: (
  sm: 1,
);

:root {
  font-size: 16px;
  --base-space: 8px;
  --color-text: black;
  --color-link: black;
  --color-bg: white;
}

// __________________________________________________________ //

// FONTS

$fonts: (
  main: (
    name: arapey-regular,
    fallback: "serif",
    withFile: true,
  ),
  title: (
    name: unbounded-semibold,
    fallback: "sans-serif",
    withFile: true,
  ),
  icons: (
    name: icons,
    fallback: "sans-serif",
    withFile: true,
  )
);

// __________________________________________________________ //

// BREAKPOINTS

$breakpoints: (
  xs: 0px,
  sm: 576px,
  md: 1075px,
  lg: 1330px,
);

// __________________________________________________________ //

// TRANSITION

$transition-property-default: all;
$transition-duration-default: 0.2s;
$transition-timing-default: cubic-bezier(0.7, 0, 0.3, 1);
$transition-delay-default: 0s;

// __________________________________________________________ //

// FREE CONFIG
