footer{
  align-self: end;
  @include font(title);
  @include fs(text-xs);
  grid-column: span 12;
  display: grid;
  grid-template-columns: repeat(12,1fr);
  align-items: end;
  padding: 3rem 1.563rem 0.75rem;
  @include mq(md){
    padding: 3rem 0 0.75rem 0;
  }
}

footer div.txt-left{
  grid-column: span 2;
  @include mq(md){
    grid-column: span 2;
  }
}
footer div.txt-center{
  order: -1;
  grid-column: span 12;
  @include mq(md){
    order: unset;
    grid-column: span 8;
  }
}
footer div.txt-right{
  grid-column: span 10;
  @include mq(md){
    grid-column: span 2;
  }
}

footer > div > ul{
  display: flex;
}

footer > .txt-left > ul{
  display: flex;
  justify-content: flex-start;
  li{
    margin-right: 0.75em;
  }
}

footer > .txt-center span{
  margin: 0 0.75em;
}

footer > .txt-right > ul{
  display: flex;
  justify-content: end;
}