i[class^="icon-"]:before, i[class*=" icon-"]:before {
    @include font(icons);
    @include fs(icons);
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-closed-menu:before {
    content: "\f101";
}
.icon-opened-menu:before {
    content: "\f102";
}
.icon-smile:before {
    content: "\f103";
}
