#mc_embed_signup{
  margin: 3rem auto;

  h2{
    @include font(title);
    @include fs(title);
    @extend .txt-center;
  }
}

.mc-field-group{
  @include font(title);
  @include fs(text-s);
}
#mc_embed_signup div#mce-responses{
  margin: 0 !important;
  padding: 0 !important;
}

#mc_embed_signup div.response{
  padding-top: 0 !important;
  margin: 0 !important;
  margin-bottom: 1em !important;
}

#mc_embed_signup .button{
  background: black !important;
  font-weight: bold !important;
}