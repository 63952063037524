// —————————————————————————————
// Article Cover
//—————————————————————————————

.category-header{
  grid-column: span 12;
  padding: 0 1.563rem;
  align-items: start;
 @include mq(md){
   margin: 0 -1.563rem;
   padding: 0 1.563rem;
   display: grid;
   grid-template-columns: repeat(12,1fr);
 }
  h1{
    grid-column: span 12;
  }
  h2{
    grid-column: 4/10;
    margin-bottom: 1.75em;
    margin-top: 0.4em;
  }
  h3{
    grid-column: span 12;
    margin-bottom: 0.2em;
    margin-top: 1.75em;
  }
}

section.featured-articles{
  grid-column: span 12;
  @include mq(md){
    grid-auto-rows: 1fr;
    margin: 0 -1.563rem;
    display: grid;
    grid-template-columns: repeat(12,1fr);
  }
  a{
    grid-column: span 6;
  }
  .featured-article{
    padding: 1.563rem;
    grid-column: span 6;
    display: grid;
    grid-template-rows: min-content min-content;
    align-items: start;
    height: 100%;
  }
  .featured-article--cover{
    picture{
      display: block;
      max-height: 100%;
      height: 100%;
      width: 100%;
      overflow: hidden;
    }
    img{
      width: 100%;
    }
  }
  .featured-article--text{
    @include mq(md){
      padding: 0 16.66666%;
    }
    h3{
      margin-bottom: 0.2em;
      margin-top: 1.75em;
    }
    .abstract{
      @include fs(text-s);
      margin-top: 0.75em;
    }
  }


}
