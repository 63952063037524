// DEFAULT PAGE TEMPLATE

body[data-template="default"]{
  main{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: start;
  }
  .container{
    padding: 0 1.563rem;
    grid-column: span 12;
    @include mq(md){
      padding: 0;
      grid-column: 3/11;
    }
  }
}

.default-cover img{
  padding: 3rem 25%;
}

strong{
  @include font(title);
  @include fs(text-s);
}