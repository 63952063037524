// —————————————————————————————
// Article Cover
//—————————————————————————————

.article--cover{
  grid-column: span 12;
  margin: 0 -1.563rem;
  padding: 0 1.563rem;
 @include mq(md){
   display: grid;
   grid-template-columns: repeat(12,1fr);
 }
  h1{
    grid-column: span 12;
  }
  h3{
    grid-column: span 12;
    margin-bottom: 0.2em;
    margin-top: 1.75em;
  }
}

.article--cover-image{
  margin-top: 2.75rem;
  grid-column: 4/10;
  picture{
    img{
      display: block;
    }
  }
}

.article--cover-image-legend{
  @include font(title);
  @include fs(text-xs);
  text-transform: uppercase;
  margin-bottom: 3em;
}



// —————————————————————————————
// Article Content
//—————————————————————————————

.article--content{
  grid-column: span 12;
  display: grid;
  grid-template-columns: repeat(12,1fr);
}

.article--content{
  h1, h2, h3, h4, h5, h6, p, blockquote{
    grid-column: 4/10;
  }
  h1, h2, h3, h4, h5, h6{
    text-align: center;
  }
  h2{
    margin-top: 1.9em;
    margin-bottom: 1.5em;
  }
  p{
    margin-bottom: 1em;
  }
  blockquote{
    margin:1em 0 2em;
    text-align: center;
    @include fs(text-m);
    em{
      font-style: italic;
    }
    footer{
      margin-top: 1em;
      padding: 0;
      display: block;
      text-align: center;
      text-transform: uppercase;
    }
  }



  section.grid{
    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-gap: 1.563rem;
    align-items: center;
    margin-bottom: 1.563rem;
    grid-column: span 12;
  }
  div.column[style*="--columns:6"] {
    grid-column: span 6;
  }
  div.column[style*="--columns:12"] {
    grid-column: span 12;
  }
  div.column[style*="--columns:12"], div.column[style*="--columns:6"] {
    picture{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// —————————————————————————————
// Article Images
//—————————————————————————————

// Layout 1/2 1/2
div.column[style*="--columns:6"] {
  .portrait.full img {
    width: 100%;
    padding: 0;
  }

  .landscape.full img {
    width: 100%;
    padding: 0;
  }

  .landscape.medium img {
    width: 100%;
    padding: 0 15%;
  }
}

// Layout 1/1
  div.column[style*="--columns:12"] {
    .portrait.medium img {
      width: calc(33.3333% - 1.563rem / 2);
      padding: 0;
    }
    .portrait.full img {
      width: calc(50% - 1.563rem / 1.8);
      padding: 0;
    }
  }
