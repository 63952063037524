// WAITING PAGE

body{
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;

/*  animation: gradient 20s infinite;
  animation-direction: alternate;*/
}

main{
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100% - var(--hh));
  @include mq(md){
    padding: 0 1.563rem;
    padding-top: 2.25rem;
    align-items: start;
  }
}

@keyframes gradient {
  0%  {background-color: #c6d6bb;}
  25% {background-color: #d9e1dd;}
  50% {background-color: #fbefb1;}
  75% {background-color: #e7d5c1;}
  100% {background-color: #d39d95;}
}

.waiting-message{
  position: fixed;
  width: 80%;
  max-width: 500px;
  @include mq(md){
    width: 500px;
  }
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  p{
    margin-top: 0.25em;
  }
}

.title{
  text-transform: uppercase;
  @include font(title);
  font-size: 0.85em;
}

.instagram{
  img{
    width: 2em;
    vertical-align: middle;
  }
}

.coming-soon{
  position: fixed;
  bottom: 0.8em;
  width: 100%;
  left: 0;
  text-align: center;
}

// WAITING PAGE


// GENERAL STYLING

h1, h2, h3, h4, h5, h6{
  font-weight: normal;
  margin: 0;
}

h1{
  @include font(title);
  @include fs(text-l);
}

h2{
  @include fs(text-m);
}

h3{
  @include font(title);
  @include fs(text-s);
}

.txt-center{
  text-align: center;
}
.txt-left{
  text-align: left;
}
.txt-right{
  text-align: right;
}

.underline{
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.45em;
}

strong{
  font-weight: bold;
}

.ul-pading{
  padding-left: 50%;
}