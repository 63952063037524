header{
  padding: 1.563rem;
  @include mq(md){
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
}

.logo{
  grid-column: span 3;
  display: flex;
  align-items: center;
  @include mq(md){
    grid-column: span 1;
  }
  @include mq(lg){
    grid-column: span 3;
  }
  @include font(title);
  @include fs(text-xs);
  .logo--img{
    width: 85px;
    margin-right: 3.5em;
  }
  .logo--baseline{
    letter-spacing: 0.04em;
    text-transform: uppercase;
    white-space: nowrap;
    @include mq(md){
      display: none;
    }
    @include mq(lg){
      display: block;
    }
  }
}

.menu-collapse{
  visibility: hidden;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr min-content;
  height: 0;
  @include mq(md){
    padding-top: 0;
    visibility: visible;
    grid-template-columns: repeat(10, 1fr);
    grid-column: span 11;
    display: grid;
    height: auto;
  }
  @include mq(lg){
    visibility: visible;
    grid-template-columns: repeat(9, 1fr);
    grid-column: span 9;
    display: grid;
    height: auto;
  }
  &.active{
    visibility: visible;
    height: calc( var(--vh)* 100 - 1.563rem *2.8);
    @include mq(md){
      height: auto;
    }
  }
}

.main-menu{
  margin-top: 2rem;
  @include mq(md){
    display: flex;
    justify-content: start;
    align-items: center;
    grid-column: span 8;
    text-align: center;
    margin-top: 0;
    a{
      display: inline-block;
      margin: 0 1.5em;
    }
    @include mq(lg){
      grid-column: span 6;
      justify-content: center;
    }
  }
  a{
    display: block;
    @include font(title);
    @include fs(text-s);
    margin-bottom: 0.3em;
    text-transform: uppercase;
    @include mq(md){
      margin-bottom: 0em;
      @include fs(menu-s);
    }

  }
}

.side-menu{
  grid-column: span 3;
  display: flex;
  align-items: center;
  @include mq(md){
    justify-content: end;
    grid-column: span 2;
  }
  @include mq(lg){
    grid-column: span 3;
  }
  a{
    margin: 0 1em;
    @include mq(md){
      margin-left: 1.5em;
    }
    white-space: nowrap;
    @include font(title);
    @include fs(text-xs);
    text-transform: uppercase;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 0.45em;
    &:first-of-type{
      margin-left: 0;
      @include mq(md){
        margin-left: 1.5em;
      }
    }
  }
  .instagram{
    margin-right: 0;
  }
}

.menu-toggler{
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right:0;
  padding: 1.563rem;
  @include mq(md){
    display: none;
  }
  i{
    @extend .icon-closed-menu;
  }
  &.closed{
    i{
      @extend .icon-opened-menu;
    }
  }
}


.search-bar{
  height: 0;
  position: relative;
  overflow: hidden;
  background: white;
  grid-column: span 12;
  margin-top: 0.2rem;
  @include mq(md){
    display: flex;
    text-align: center;
    justify-content: center;
  }
  bottom: 4em;
  @include mq(md){
    bottom: 0;
  }
  input, button{
    line-height: 1.2rem;
    @include mq(md){
      line-height: 0.5rem !important;
    }
    text-align: center;
    text-transform: uppercase;
    @include font(title);
    @include fs(menu-s);
    border-radius: 0;
    border: none;
    background: white !important;
    -webkit-appearance: none;
    color: black;
    font-weight: normal;
  }
  form{
    display: flex;
    align-items: end;
    padding-right: 1em;
    @include mq(md){
      display: block;
    }
  }
  input{
    flex: 1;
    @include mq(md){
      min-width: 480px;
    }
    border-bottom: 1px solid;
  }
  input:focus{
    outline: none;
  }
  button{
    cursor: pointer;
  }
  .close-search{
    position: absolute;
    right: 0;
    margin-top: 0.51rem;
    font-size: 0.6em;
    cursor: pointer;
  }

}

