section.featured{
  @extend section, .featured-articles;
}

.cover-image, .cover-text{
  @extend .featured-article;
  p {
    text-indent: 1.5em;

    &:first-of-type {
      text-indent: 0;
    }
  }
}

.cover-text{
  padding-top: 0 !important;
  @include mq(md){
    padding-top: 1.563rem !important;
  }
}

.events-grid{
  max-width: 1600px;
  margin: 0 auto;
  margin-bottom: 1em;
  @extend .articles-grid;
  gap: calc(1.563rem + 7.75%);
  display: block;
  @include mq(md){
    display: grid;
  }
}

.event{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  margin-bottom: 3.25em;
  @include mq(md){
    margin-bottom: 0;
  }
  @include font(title);
  h2, .txt-center, picture, .cover-text{
    grid-column: span 2;
    text-align: center;
  }
  p{
    font-size: 0.5833333333333334em;
  }
  picture{
    overflow: hidden;
    aspect-ratio: 3/2;
    img{
      object-fit: cover;
      height: 100%;
    }
    &.portrait img{
      object-fit: contain;
    }
  }
  h2{
    font-size: 1em;
    margin-top: 0.4em;
    margin-bottom: 0.55em;
  }
  p.txt-center{
    margin-bottom: 1.5em;
    line-height: 1.2em;
  }
  .cover-text{
    text-align: left;
    margin-top: 0.75em;
    p{
      text-indent: 1.5em;
      &:first-of-type{
        text-indent: 0;
      }
      @include font(main);
      line-height: 1em;
      @include mq(md){
        font-size: 0.75em;
      }
    }

  }
}